import React from 'react';
import TrueGrid from "../../../../../../theme/components/containers/TrueGrid";
import TabContainer from "../../components/TabContainer";

const NineOne = (props) => {
    return (
        <>
            Текст про мансардный этаж
        </>
    );
};

export default NineOne;