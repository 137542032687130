import React from "react";
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {palette} from "../../Theme";
import useMobile from "../../functions/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    boxIcon: {
        fontSize: 25,
        marginRight: 10,
        display: "flex",
        alignItems: "center",
        transform: "translateY(-1px)",
        color: "inherit",
    },
    h: {
        color: "inherit",
        fontSize: 18,
        transition: "all 0.5s"
    },
    flex: {
        display: "flex",
        alignItems: "center",
    },
    flexLink: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "all 0.5s",
        "&:hover": {
            color: `${palette.accent} !important`
        }
    },
    count: {
        color: "gray",
        marginLeft: 10,
        fontSize: "0.8em"
    }
}))

function Header(props) {
    const classes = useStyles();
    const isMobile = useMobile()

    function getFontSize(type) {
        switch (type) {
            case 0:
                return isMobile? 45:65
            case 1:
                return  isMobile? 30 : 55
            case 2:
                return isMobile? 25: 40
            case 3:
                return 21
            case 4:
                return 18
            default:
                return 20
        }
    }

    return (
        <Box className={`${props.onClick? classes.flexLink: classes.flex} ${props.mb? "mb-3" : ""} ${props.className}`} style={{...props.style}} onClick={props.onClick}>
            {props.icon && <Box className={classes.boxIcon} style={{color: props.color}}>{props.icon}</Box>}
            <Typography component="div" className={classes.h} style={{fontSize: getFontSize(props.h), fontWeight: 600, ...props.fontStyle}} ref={props.textRef}>
                {props?.title}{props.count ? <span className={classes.count}>{props.count}</span> : <span/>}
            </Typography>
        </Box>
    )
}

Header.propTypes = {
    mt: PropTypes.bool,
    mb: PropTypes.bool,
    h: PropTypes.oneOf([1, 2, 3, 4, 5]),
    id: PropTypes.string,
    title: PropTypes.any,
    count: PropTypes.number,
    icon: PropTypes.element,

    className: PropTypes.string,
    style: PropTypes.object,
    fontStyle: PropTypes.object,
}

export default Header
