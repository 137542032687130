import React, {useEffect, useState} from 'react';

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false)

    const mediaQuery = window.matchMedia('(max-width: 1200px)')

    function checkIsMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent) || mediaQuery.matches) {
            setIsMobile( true)
            //alert("Вы используете мобильное устройство (телефон или планшет).")

        } else {
            setIsMobile( false)
            //alert("Вы используете ПК.")
        }
    }

    //document.addEventListener("resize", (event) => {checkIsMobile()});
    //checkIsMobile()
    useEffect(()=>{
        checkIsMobile()
        window.addEventListener("resize", (event) => {checkIsMobile()});

        return ()=> window.removeEventListener("resize", (event) => {checkIsMobile()});
    }, [])

    return isMobile
};

export default useMobile;