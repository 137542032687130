import React, {useEffect} from 'react';
import TrueGrid from "../../../../theme/components/containers/TrueGrid";
import TextInput from "./TextInput";
import TextSelect from "./TextSelect";
import {connect} from "react-redux";
import {getLocations} from "../locations/reducer";
import {getBuildings} from "../buildings/reducer";
import {getDocuments} from "../documents/reducer";
import TextMultipleSelect from "./TextMultipleSelect";

const Selects = (props) => {
    const {form, formHandler} = props

    useEffect(()=>{
        props.getLocations()
        props.getBuildings()
        props.getDocuments()
    }, [])

    return (
        <TrueGrid gap={22}>
            <TextSelect value={form.building} setValue={e=>formHandler({building: e})} title={"Тип здания*"} placeholder={"Выберите тип здания"} values={props.buildingValues} required/>
            <TextSelect value={form.location} setValue={e=>formHandler({location: e})} title={"Местоположение"} placeholder={"Введите местоположение"} values={props.locationValues}/>
            <TextInput value={form.people_num} setValue={e=>formHandler({people_num: e})} title={"Количество человек"} placeholder={"Введите количество человек"}/>
            <TextInput value={form.building_area} setValue={e=>formHandler({building_area: e})} title={"Площадь"} placeholder={"Введите площадь"}/>
            <TextMultipleSelect value={form.documents} setValue={e=>formHandler({documents: e})} title={"Выбрать документ"} placeholder={"Выберите номер или наименование документа"} values={props.documentValues}/>

            <div style={{color:"#fff", fontSize: 16}}>* Поле, обязательное для заполнение</div>
        </TrueGrid>
    );
};

const mapStateToProps = (state) => ({
    locationValues: state.check.locations.data,
    buildingValues: state.check.buildings.data,
    documentValues: state.check.documents.data,
})

export default connect(mapStateToProps, {
    getLocations,
    getBuildings,
    getDocuments,
})(Selects)