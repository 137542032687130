import React from 'react';
import {containerWidth} from "../../theme/Theme";
import FlexContainer from "../../theme/components/containers/FlexContainer";
import fond from "./img/fond.png"
import FooterLink from "./components/FooterLink";
import {DEFAULT_PATH} from "../../services/router/pageConsts";
import {routes} from "../../services/router/routes";
import useMobile from "../../theme/functions/hooks/useMobile";
import TrueGrid from "../../theme/components/containers/TrueGrid";

const Footer = () => {
    const isMobile = useMobile()
    return (
        <div style={{background: "linear-gradient(104.99deg, #54718A 10.52%, #005EDE 107.22%)"}} id={"contacts"}>
            <div style={{ maxWidth: containerWidth, padding: "26px 20px", display: "block", margin: "auto"}}>
                {isMobile?
                    <TrueGrid gap={20}>
                        <img src={fond} alt={"fond"}  style={{height: 72, objectFit: "contain"}}/>

                        <FlexContainer gap={10}>
                            <FooterLink to={DEFAULT_PATH} text={"Главная"}/>
                            <FooterLink to={routes.check.path} text={routes.check.name}/>
                        </FlexContainer>

                        <TrueGrid gap={5}>
                            <FooterLink to={"#"} text={"Политика конфиденциальности"}/>
                            <FooterLink to={"#"} text={"Пользовательское соглашение"}/>
                        </TrueGrid>

                        <TrueGrid gap={5}>
                            <FooterLink to={"mailto: normastroy@gmail.com"} text={"normastroy@gmail.com"}/>
                            <FooterLink to={"#"} text={"г. Санкт-Петербург"}/>
                        </TrueGrid>
                    </TrueGrid>:
                    <FlexContainer>
                        <img src={fond} alt={"fond"}  style={{height: 72, objectFit: "contain"}}/>
                        <FlexContainer style={{flexDirection: "column", marginLeft: "auto"}} gap={20}>
                            <FlexContainer>
                                <FooterLink to={DEFAULT_PATH} text={"Главная"} style={{textDecoration: "underline"}}/>
                                <FooterLink to={routes.check.path} text={routes.check.name} style={{textDecoration: "underline"}}/>
                            </FlexContainer>
                            <FlexContainer>
                                <FooterLink to={"#"} text={"Политика конфиденциальности"}/>
                                <FooterLink to={"#"} text={"Пользовательское соглашение"}/>
                            </FlexContainer>
                        </FlexContainer>
                        <FlexContainer style={{flexDirection: "column", marginLeft: "auto", alignItems: "flex-end"}} gap={20}>
                            <FooterLink to={"mailto: normastroy@gmail.com"} text={"normastroy@gmail.com"}/>
                            <FooterLink to={"#"} text={"г. Санкт-Петербург"}/>
                        </FlexContainer>
                    </FlexContainer>
                }
            </div>
        </div>
    );
};

export default Footer;