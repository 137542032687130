import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from "../../../../../theme/components/containers/FlexContainer";
import {Button} from "@mui/material";
import arrowRight from "../img/arrowRight.png";
import Header from "../../../../../theme/components/typography/Header";
import TrueGrid from "../../../../../theme/components/containers/TrueGrid";
import useMobile from "../../../../../theme/functions/hooks/useMobile";

const TabContainer = (props) => {
    const isMobile= useMobile()
    return (
        <TrueGrid gap={20}>
            <Header h={2} title={props.title}/>

            <div>
                Пункт {props.number}
            </div>

            { isMobile?
                <TrueGrid mobileTemplate={"1fr 1fr 1fr"} alignItems={"center"}>
                    <Button variant={"outlined"} style={{color: "#fff", borderColor: "#fff"}}>Семейство</Button>
                    <img src={arrowRight} alt={"right"} style={{display: "block", margin: "auto"}}/>
                    <Button variant={"contained"}>Уровень</Button>
                </TrueGrid>:
                <FlexContainer gap={15}>
                    <Button variant={"outlined"} style={{color: "#fff", borderColor: "#fff"}}>Семейство</Button>
                    <img src={arrowRight} alt={"right"}/>
                    <Button variant={"contained"}>Уровень</Button>
                </FlexContainer>
            }

            <TrueGrid gap={20}>
                {props.children}
            </TrueGrid>

            {!!props.subTabs && props.subTabs.map(subEl=>
                subEl.content &&
                    <div style={{marginTop: 40}}>
                        <TabContainer title={subEl.title} number={subEl.number}>
                            { subEl.content}
                        </TabContainer>
                    </div>
            ) }
        </TrueGrid>
    );
};

TabContainer.propTypes = {
    title: PropTypes.object.isRequired,
    number: PropTypes.any.isRequired,
};

export default TabContainer;