//import axios from "axios";
import localStorageService from "./localStorageService";
import {callApi} from "./apiCalls";
import {AUTH_PATH} from "../const/paths";


class JwtAuthService{

    loginWithEmailAndPassword = (email, password) => {
        const headers = {'Content-Type': 'application/json'};

        return callApi("/authPages/login", {
            method: 'POST',
            headers,
            body: JSON.stringify({
                username: email,
                password,
            }),
        }).then(data => {
            // Token is valid
            // this.setSession(data.token);
            this.setUser(data);
            return data;
        }).catch(err => alert(err.Error()));
        /*return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(this.sources);
            }, 1000);
        }).then(data => {
            // Login successful
            // Save token
            this.setSession(data.token);
            // Set sources
            this.setUser(data);
            return data;
        });*/
    };

    // You need to send http requst with existing token to your server to check token is valid
    // This method is being used when sources logged in & app is reloaded
    loginWithToken = () => {

        const headers = {'Content-Type': 'application/json'};

        return callApi(AUTH_PATH, {
            method: 'GET',
            headers,
        }).then(data => {
            // Token is valid
            this.setSession(data.token);
            this.setUser(data);
            return data;
        });
        /*return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(this.sources);
          }, 100);
        }).then(data => {
          // Token is valid
          this.setSession(data.token);
          this.setUser(data);
          return data;
        });*/
    };

    logout = () => {
        this.setSession(null);
        this.removeUser();
    }

    // Set token to all http request header, so you don't need to attach everytime
    setSession = token => {
        if (token) {
            localStorage.setItem("jwt", token);
            //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        } else {
            localStorage.removeItem("jwt");
            //delete axios.defaults.headers.common["Authorization"];
        }
    };

    // Save sources to localstorage
    setUser = (user) => {
        localStorageService.setItem("auth_user", user);
    }
    // Remove sources from localstorage
    removeUser = () => {
        localStorage.removeItem("auth_user");
    }
}

export default new JwtAuthService();
