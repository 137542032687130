import React, {useRef, useState} from 'react';
import TrueGrid from "../../../theme/components/containers/TrueGrid";
import {Button, Card} from "@mui/material";
import Section from "../../../theme/components/containers/Section";
import Selects from "./components/Selects";
import FlexContainer from "../../../theme/components/containers/FlexContainer";
import CheckerAction from "./components/CheckerAction";
import check1 from "./img/check1.png";
import check2 from "./img/check2.png";
import check3 from "./img/check3.png";
import check4 from "./img/check4.png";
import check5 from "./img/check5.png";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../services/router/routes";
import useMobile from "../../../theme/functions/hooks/useMobile";
import {connect} from "react-redux";
import {getLocations} from "./locations/reducer";
import {getBuildings} from "./buildings/reducer";
import {getDocuments} from "./documents/reducer";
import {postModel} from "./reducer";
import {API_PATH} from "../../../services/const/paths";
import axios from "axios";
import {logError, logSuccess} from "../../../theme/functions/errors";


const Checker = (props) => {
    const isMobile = useMobile()
    const navigate = useNavigate()
    const [file, setFile] = useState(null)
    const ref = useRef()
    const [newId, setNewId] = useState(0)

    function downloadReport() {
        if (!newId) return
        axios.get(`${API_PATH}/model/${newId}`).then(function (response) {
            logSuccess("Файл сейчас появится в загрузках")
            const fileData = JSON.stringify(response.data);
            const blob = new Blob([fileData], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "report.txt";
            link.href = url;
            link.click();
            link.remove()

        }).catch(function (response) {
            logError("Не удалось скачать файл")
            console.log(response);
        });
    }

    const checkActions = [
        {
            text: "Загрузить модель",
            src: check1,
            width: 260,
            active: !!file,
            activeText: "Модель загружена"
        },
        {
            text: "Скачать отчет",
            src: check2,
            width: 280,
            active: !!newId,
            activeText: "Скачать отчет",
            onClick: ()=>downloadReport()
        },
        {
            text: "Скачать пример модели",
            src: check3,
            width: 340,
        },
        {
            text: "Инструкция",
            src: check4,
            width: 200,
            onClick: ()=>navigate(routes.instruction.path)
        },
        {
            text: "Скачать файл общих параметров",
            src: check5,
            width: 420,
        },
    ]

    const DEFAULT_STATE = {
        building: "",
        location: "",
        people_num: "",
        building_area: "",
        documents: [],
    }

    const [form, setForm] = useState(DEFAULT_STATE)
    const formHandler = (newObj) => {
        setForm({...form, ...newObj})
    }

    function onPost(){
        const formData = new FormData();
        formData.append("file", file);
        formData.append("request", JSON.stringify(form));

        axios.post(`${API_PATH}/model/`, formData).then(function (response) {
            setNewId(response?.data?.report_id)
            logSuccess("Отчет успешно сформирован, теперь можно его скачать")
        }).catch(function (response) {
            logError("Не удалось загрузить файл")
            console.log(response);
        });

    }

    const fileHandler = e => {
        setFile(e.target.files[0])
        setNewId(0)
    }

    return (
        <Section style={{paddingTop: 0}}>
            <TrueGrid columns={2} gap={40} alignItems={"start"}>
                { isMobile &&
                    <FlexContainer gap={20}>
                        <label style={{width: "100%"}}>
                            <input type={"file"} style={{display: "none"}} onChange={fileHandler} ref={ref}/>
                            <CheckerAction item={checkActions[0]}/>
                        </label>
                        {checkActions.slice(1).map(el=><CheckerAction item={el} key={el.src}/>)}
                    </FlexContainer>
                }
                <TrueGrid gap={40}>
                    <Card style={{padding: 32, borderRadius: 10, background: "#225078"}}>
                        <Selects form={form} formHandler={formHandler}/>
                    </Card>
                    <Button variant={"contained"} fullWidth={true} onClick={onPost} disabled={!file || !form.building?.length} style={{paddingTop: 25, paddingBottom: 25}}>
                        Провести проверку
                    </Button>
                </TrueGrid>
                { !isMobile &&
                    <FlexContainer gap={20}>
                        <label>
                            <input type={"file"} style={{display: "none"}} onChange={fileHandler} ref={ref}/>
                            <CheckerAction item={checkActions[0]}/>
                        </label>
                        {checkActions.slice(1).map(el=><CheckerAction item={el} key={el.src}/>)}
                    </FlexContainer>
                }
            </TrueGrid>
        </Section>
    );
};

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, {
    postModel,
})(Checker)