import React from 'react';
import {routes} from "../../services/router/routes";
import Layout from "../../layout/containers/Layout";
import Header from "../../theme/components/typography/Header";
import Section from "../../theme/components/containers/Section";
import Hr from "../../theme/components/typography/Hr";
import Checker from "./checker/Checker";
import useMobile from "../../theme/functions/hooks/useMobile";

const CheckPage = () => {
    const isMobile = useMobile()
    return (
        <Layout title={routes.check.name} fetching={false}>
            <Section style={{paddingBottom: 35}}>
                <Header h={1} title={"Normastroy"} style={{justifyContent: "center"}}/>
                <p style={{textAlign: "center", fontSize: isMobile? 20:28, marginTop: isMobile? 5:0}}>Приложение для проверки зданий по НТД</p>
                <Hr style={{marginTop: 30}}/>
            </Section>
            <Checker/>
        </Layout>
    );
};

export default CheckPage;