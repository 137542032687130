import {REMOVE_USER_DATA, SET_USER_DATA, USER_LOGGED_OUT} from "../actions/userActions";

const initialState = {};

const userReducer = function (state = initialState, {type, payload}) {
    switch (type) {
        case SET_USER_DATA: {

            // TODO: сделать на сервере
            let role = "user";
            if (!!payload && !!payload.isAdmin) {
                role = "admin";
            }

            return {
                ...state,
                ...payload,
                role,
            };
        }
        case REMOVE_USER_DATA: {
            return {
                ...state
            };
        }
        case USER_LOGGED_OUT: {
            return state;
        }
        default: {
            return state;
        }
    }
};

export default userReducer;
