import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import "./style.css"
import down from "../img/down.png"
import up from "../img/up.png"

const TextSelect = props => {
    const {value, setValue, values} = props

    const [display, setDisplay] = useState("none")
    const isFlex = display==="flex"
    const openHandler = () => setDisplay(isFlex? "none": "flex")

  /*  function getLabel (value) {
        for (let el of values)
            if (el.value === value) return el.label
        return ""
    }*/

    function setValueHandler (newValue) {
        setValue(newValue)
        openHandler()
    }

    return (
        <div>
            <div>
                <b style={{color: "#fff"}}>{props.title}</b>
            </div>
            <div style={{
                    marginTop: 10,
                    background: "transparent",
                    borderRadius: 10,
                    border: "2px solid #fff",
                    display: "flex",
                    width: "100%",
                    borderBottomLeftRadius: isFlex? "0px" : "10px",
            }}>
                <input placeholder={props.placeholder} disabled={true} className={"white-placeholder"} value={value} style={{
                    background: "transparent",
                    display: "block",
                    width: "100%",
                    outline: "none",
                    border: "none",
                    padding: "10px 15px",
                }}/>
                <div onClick={openHandler} style={{
                    display: "block",
                    margin: "auto",
                    padding: "5px 10px",
                    borderLeft: "2px solid #fff",
                    cursor: "pointer",
                    background: isFlex? "#fff": "",
                    borderRadius: "0px 7px 7px 0px"
                }}>
                    <img src={isFlex? up: down} alt={"down"} style={{width: 14, objectFit: "contain"}}/>
                </div>
            </div>

            <div style={{maxHeight: 400, overflowY: "auto",   marginRight: 10, paddingRight: 5, scrollbarGutter: "stable"}} className={"custom-scroll-y"}>
                {!!values?.length && values.map((el, index)=>
                    <div onClick={()=>setValueHandler(el)} key={el} style={{
                        display: display,
                        color: "#fff",
                        padding: "10px 15px",
                        border: "2px solid #fff",
                        marginTop: -2,
                        fontSize: 16,
                        gap: "15px",
                        alignItems: "center",
                        cursor: "pointer",
                        //borderRadius: index === values?.length -1 ? "0px 0px 10px 10px" : "0px",
                        borderRadius: 0,
                    }}>
                        <div style={{width: 14, height: 14,aspectRatio: "1/1", border: "1px solid #fff", borderRadius: "50%", background: el === value? "#fff": ""}}/>
                        {el}
                    </div>
                )}
            </div>
            {isFlex &&
                <div style={{width: "min(471px, 100%)", height: 2, background: "#fff"}}/>
            }
        </div>
    );
};

TextSelect.propTypes = {
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    values: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default TextSelect;