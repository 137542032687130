import React, {useEffect, useRef} from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {toTop} from "../../theme/functions/functions";
import TopMenu from "../header/TopMenu";
import "./style.css"
import Footer from "../footer/Footer";
import {ToastContainer} from "react-toastify";
import {ArrowUpward} from "@mui/icons-material";
import useMobile from "../../theme/functions/hooks/useMobile";
import MobileMenu from "../header/MobileMenu";
import toTopImg from "../assets/img/toTop.png"

const useStyles = makeStyles((theme) => ({
    toTop: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: "none",
        transition: "all 0.5s",
        borderRadius: 10,
        cursor: "pointer",
        maxWidth: 60,
        "&:hover":{
            transform: "translateY(-5px)"
        }
    }
}))


const SiteContainer = (props) => {
    const classes = useStyles()
    const ref = useRef(null)
    const isMobile = useMobile()
    function scroller () {
        if (window.scrollY>50) ref.current.style.display = "flex"
        else ref.current.style.display = "none"
    }

    useEffect(()=>{
        //scroller()
        document.addEventListener("scroll", scroller)
        return ()=>document.removeEventListener("scroll", scroller)
    }, [])

    return (
        <Box>
            <ToastContainer autoClose={3005}/>
            {isMobile?
                <MobileMenu/>:
                <TopMenu/>
            }

            <main>
                {props.children}
            </main>

            <Footer/>

            <div className={classes.toTop} ref={ref} onClick={toTop}>
                <img src={toTopImg} alt={"toTOp"}/>
            </div>
        </Box>
    );
};

export default SiteContainer;
