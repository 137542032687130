import React from 'react';
import PropTypes from 'prop-types';
import useMobile from "../../functions/hooks/useMobile";

const Section = props => {
    //isMobile? "40px 25px":"64px 20px
    const isMobile = useMobile()
    return (
        <section style={{paddingTop: isMobile? 40:64, paddingBottom: isMobile? 40:64, paddingLeft: isMobile? 25:20, paddingRight: isMobile? 25:20, ...props.style}}>
            {props.children}
        </section>
    );
};

Section.propTypes = {

};

export default Section;