import React, {useEffect, useRef} from 'react';
import {Box, Container} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {containerWidth, palette, sticky} from "../../theme/Theme";
import {useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import {PROJECT_NAME} from "../../services/const";
import Header from "../../theme/components/typography/Header";
import PropTypes from "prop-types";
import TopLoader from "../../theme/components/containers/TopLoader";

const useStyles = makeStyles((theme) => ({

}))


const Layout = (props) => {
    const navigate = useNavigate()

    document.title = `${PROJECT_NAME} - ${props.title}`


    return (
        <Box className={`mt-1`} style={{maxWidth: props.maxWidth ?? "", marginInline: "auto"}}>
            <TopLoader fetching={props.fetching}/>

            <Container style={{maxWidth:containerWidth, padding: 0 }}>
                <Box>
                    {props.children}
                </Box>
            </Container>
        </Box>
    );
};

Layout.propTypes = {
    title: PropTypes.string.isRequired,
    fetching: PropTypes.bool.isRequired,
    maxWidth: PropTypes.any,

    back: PropTypes.bool,
    onClick: PropTypes.func,
    icon: PropTypes.func,
    headerAction: PropTypes.element,
};


export default Layout;
