import React from "react";

export const toTop = () => window.scrollTo({top: 0, left: 0, behavior: 'smooth'})

export const scrollTo = (id) => {
    let to = document.getElementById(id)
    if(!to) return
    to.scrollIntoView()
}

export function parseToQuery(obj){
    if (!obj) return ""
    if (!Object.keys(obj).length) return ""

    let query= ""
    Object.entries(obj).map(([key, value])=> query+=`&${key}=${value}`)

    return query.replace("&", "?").replaceAll("+", "%2B")
}

export function calcBetter(count){
    if (!count) return 0
    if (count>=1000000) return Math.round(count/1000000 *10)/10+"М"
    else if (count>=1000) return Math.round(count/1000 *10)/10+"K"
    return count
}

export function subStr(str, substr){
    if (!str) return ""
    return str.length>substr ? str.substring(0, substr-2)+"..." : str
}

export const isMobileFunc = () => {
    const mediaQuery = window.matchMedia('(max-width: 1200px)')

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
        .test(navigator.userAgent) || mediaQuery.matches) {
        return true
        //alert("Вы используете мобильное устройство (телефон или планшет).")

    } else {
        return false
        //alert("Вы используете ПК.")
    }
}

export const onWindowOpen = (e, url, extraFunc = null) => {
    e.preventDefault()
    if (extraFunc) extraFunc()

    window.open(url, "popup", "popup")
}
