import React from 'react';
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    flexContainer:{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 15
    },
}))


const FlexContainer = (props) => {
    const classes = useStyles();

    function getGap(){
        if (props.gap) return props.gap

        switch (props.type){
            case "chip": return 15
            case "action": return 25
            case "stat": return 10
            default: return 30
        }
    }

    return (
        <div className={`${classes.flexContainer} ${props.mb? "mb-3" : ""} ${props.className}`} style={{gap: getGap(), ...props.style}}>
            {props.children}
        </div>
    );
};

FlexContainer.propTypes = {
    type: PropTypes.oneOf(['chip', 'action', "stat"]),
    gap: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    mb: PropTypes.bool,
}

export default FlexContainer;
