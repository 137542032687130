import {applyMiddleware} from 'redux'
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {routerMiddleware} from "react-router-redux";
import {createBrowserHistory} from "history";

import api from '../../../services/calls/apiCalls';


// Create a tasks of your choosing (we're using a browser tasks in this case)
const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

const mws = [thunk, middleware, logger, api];

export default applyMiddleware(...mws);
