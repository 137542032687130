import "react-toastify/dist/ReactToastify.css";
import React from 'react';
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';
import {store} from './services/redux/reducers'
import Theme from "./theme/Theme";
import MyRouter from "./services/router/MyRouter";
import {DEFAULT_APP_ADDRESS} from "./services/const/paths";
import SiteContainer from "./layout/containers/SiteContainer";
import "./theme/styles/App.css"

function App() {
    let basename = process.env?.PUBLIC_URL
    if (!basename) {
        basename = DEFAULT_APP_ADDRESS
    }

    return (
        <Provider store={store}>
            <BrowserRouter basename={basename}>
                <Theme>
                    <SiteContainer>
                        <MyRouter/>
                    </SiteContainer>
                </Theme>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
