import React from 'react';
import {Link} from "react-router-dom";

const FooterLink = (props) => {
    return (
        <Link to={props.to} className={"hover-underline"} onClick={props.onClick}>
            <div style={props.style}>{props.text}</div>
        </Link>
    );
};

export default FooterLink;