import {REMOVE_USER_DATA, SET_USER_DATA, USER_LOGGED_OUT} from "../../../../services/auth/userReducer";
import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {logError} from "../../../../theme/functions/errors";
import {call} from "../../../../services/calls/calls";
import {API_PATH} from "../../../../services/const/paths";

const GET_DATA = "GET_LOCS"

const DEFAULT_STATE = {
    fetching: false,
    data: []
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_DATA:
            return {
                ...state,
                fetching: true,
                data: DEFAULT_STATE.data
            }

        case SUCCESS + GET_DATA:
            return {
                ...state,
                fetching: false,
                data: payload?.locations || DEFAULT_STATE.data
            }

        case  FAILED + GET_DATA:
            logError("Не удалось получить локации")
            return {
                ...state,
                fetching: false,
            }
        default: {
            return state;
        }
    }
};

export const getLocations = () => call(
    "GET",
    `${API_PATH}/locations`,
    GET_DATA
)