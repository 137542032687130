import React, {useState} from 'react';
import NineTab from "./tabsContent/9/NineTab";
import TrueGrid from "../../../theme/components/containers/TrueGrid";
import {customScrollY} from "../../../theme/Theme";
import {makeStyles} from "@mui/styles";
import Header from "../../../theme/components/typography/Header";
import NineOne from "./tabsContent/9/1/NineOne";
import TabContainer from "./tabsContent/components/TabContainer";
import useMobile from "../../../theme/functions/hooks/useMobile";
import chevronRight from "./img/chevronRight.png"
import chevronLeft from "./img/chevronLeft.png"

const useStyles = makeStyles((theme) => ({
    overflow: {
        maxHeight: "calc(100vh - 20px)",
        top: 10,
        position: "sticky",
        ...customScrollY,
    }
}))

const Tabs = () => {
    const isMobile = useMobile()
    const classes = useStyles()
    const tabs = [
        {
            title: "1. Автомобильная стоянка",
            content: null,
            subTabs: [],
        },
        {
            title: "2. Геодезическая отметка",
            content: null,
            subTabs: [],
        },
        {
            title: "3. Границы земельного участка",
            content: null,
            subTabs: [],
        },
        {
            title: "4. Отмостка",
            content: null,
            subTabs: [],
        },
        {
            title: "5. Помещение",
            content: null,
            subTabs: [],
        },
        {
            title: "6. Проезд",
            content: null,
            subTabs: [],
        },
        {
            title: "7. Проход",
            content: null,
            subTabs: [],
        },
        {
            title: "8. Пятно застройки",
            content: null,
            subTabs: [],
        },
        {
            title: "9. Этаж",
            content: <NineTab/>,
            number: <span>3.1.39 <b style={{textDecoration: "underline"}}>СП 118.13330.2022</b></span>,
            subTabs: [
                {
                    title: "9.1 Этаж мансардный",
                    number: <span>3.1.39 <b style={{textDecoration: "underline"}}>СП 118.13330.2022</b></span>,
                    content: <NineOne/>,
                },
                {
                    title: "9.2 Этаж наземный",
                    content: null,
                },
                {
                    title: "9.3 Этаж первый",
                    content: null,
                },
                {
                    title: "9.4 Этаж подвальный",
                    content: null,
                },
                {
                    title: "9.5 Этаж подземный",
                    content: null,
                },
                {
                    title: "9.6 Этаж технический",
                    content: null,
                },
            ],
        },

    ]

    const [tab, setTab] = useState([8])
    const tabHandler = (first, second) => setTab(second===-1? [first]: [first, second])

    const [display, setDisplay] = useState("none")
    const displayHandler = () => setDisplay(display==="block"? "none" : "block")

    const TabGrid = () => {
        return (
            <TrueGrid gap={5}>
                {tabs.map((el, index)=>
                    <React.Fragment  key={el.title}>
                        <div onClick={()=>tabHandler(index, -1)} style={{
                            background: tab[0]===index? "#fff":"#225078",
                            padding: "15px 30px",
                            borderRadius: 10,
                            color: tab[0]===index? "#103F62" : "#fff",
                            cursor: "pointer",
                        }}>
                            {el.title}
                        </div>
                        { tab[0]===index && el?.subTabs.map((subEl, subIndex)=>
                            <div onClick={()=>tabHandler(index, subIndex)} style={{
                                background: tab[1]===subIndex? "#fff":"transparent",
                                padding: "10px 30px",
                                borderRadius: 10,
                                border: "1px solid #fff",
                                color: tab[1]===subIndex? "#103F62" : "#fff",
                                cursor: "pointer",
                                marginLeft: 50,
                            }}>
                                {subEl.title}
                            </div>
                        )}
                    </React.Fragment>
                )}
            </TrueGrid>
        )
    }

    return (
        <TrueGrid template={"400px auto"} gap={60} alignItems={"start"}>
            {isMobile?
                null :
                <div className={classes.overflow}>
                    <TabGrid/>
                </div>
            }
            { tab?.length === 1?
                <TabContainer title={tabs[tab[0]].title} number={tabs[tab[0]].number} subTabs={tabs[tab[0]].subTabs}>
                    {tabs[tab[0]].content ?? "Вкладка находится в разработке"}
                </TabContainer>:
                <TabContainer title={tabs[tab[0]]?.subTabs[tab[1]]?.title} number={tabs[tab[0]]?.subTabs[tab[1]]?.number}>
                    {tabs[tab[0]]?.subTabs[tab[1]]?.content ?? "Вкладка находится в разработке"}
                </TabContainer>
            }

            { isMobile && display==="none" &&
                <div onClick={displayHandler} style={{
                    position: "fixed",
                    top: 75,
                    left: -1,
                    background: "#0E3A5B",
                    borderRadius: "0px 10px 10px 0px",
                    border: "1px solid #fff",
                    padding: "12px 12px 12px 35px",
                    cursor: "pointer",
                    zIndex: 9,
                }}>
                    <img src={chevronRight} style={{height: 15, width: "contain"}}/>
                </div>
            }

            { isMobile && display==="block" &&
                <div onClick={displayHandler} style={{
                    position: "fixed",
                    top: 75,
                    right: 15,
                    background: "#0E3A5B",
                    borderRadius: "0px 10px 10px 0px",
                    border: "1px solid #fff",
                    padding: "12px 15px",
                    cursor: "pointer",
                    zIndex: 9,
                }}>
                    <img src={chevronLeft} style={{height: 15, width: "contain"}}/>
                </div>
            }

            <div style={{
                position: "fixed",
                background: "#0E3A5B",
                display: display,
                top: 75,
                zIndex:9,
                height: "calc(100vmax - 75px)",
                maxHeight: "calc(100vmax - 75px)",
                overflow: "auto",
                left: 0,
                width: "calc(100vw - 60px)",
                borderRight: "1px solid #fff",
                padding: "40px 25px"
            }}>
                <TabGrid/>
            </div>
        </TrueGrid>
    );
};

export default Tabs;