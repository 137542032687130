import React, {lazy} from "react";
import Page404 from "./Page404";
import {Check} from "@mui/icons-material";
import CheckPage from "../../scenes/check/CheckPage";
import InstructionPage from "../../scenes/inctruction/InstructionPage";

const HomePage = lazy(()=> import("../../scenes/home/HomePage"));

export const routes = {
    404: {
        path: "*",
        name: "Ошибка 404. Страница отсутствует",
        component: <Page404/>
    },
    home: {
        name: "Главная",
        path: "/",
        component: <HomePage/>,
    },
    check: {
        name: "Проверить модель",
        path: "/check",
        component: <CheckPage/>,
    },
    instruction: {
        name: "Инструкция",
        path: "/instruction",
        component: <InstructionPage/>,
    },
}
