import React from 'react';
import Layout from "../../layout/containers/Layout";
import FlexContainer from "../../theme/components/containers/FlexContainer";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {routes} from "./routes";

const Page404 = () => {
    const navigate = useNavigate();

    return (
        <Layout title={routes["404"].name} fetching={false}>
            <FlexContainer type={"action"}>
                <Button onClick={()=>navigate(`/`)}>Домой</Button>
            </FlexContainer>
        </Layout>
    );
};

export default Page404;
