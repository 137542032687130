import React from 'react';
import {Table, TableCell, TableHead, TableRow} from "@mui/material";
import nine_1 from "./img/9_1.png"
import nine_2 from "./img/9_2.png"
import Header from "../../../../../theme/components/typography/Header";
import FlexContainer from "../../../../../theme/components/containers/FlexContainer";
import useMobile from "../../../../../theme/functions/hooks/useMobile";
import InstructionImgContainer from "../components/InstructionImgContainer";

const NineTab = (props) => {
    const isMobile = useMobile()
    return (
        <>
            <Table>
                <TableHead>
                    <TableCell>Параметр</TableCell>
                    <TableCell>Значение параметра</TableCell>
                </TableHead>
                <TableRow>
                    <TableCell>(1) Уровень земли</TableCell>
                    <TableCell>да/нет</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>(2) Тип этажа</TableCell>
                    <TableCell>
                        <div>Этаж мансардный</div>
                        <div>Этаж наземный</div>
                        <div>Этаж первый</div>
                        <div>Этаж подвальный</div>
                        <div>Этаж подземный</div>
                        <div>Этаж технический</div>
                        <div>Этаж цокольный</div>
                    </TableCell>
                </TableRow>
            </Table>

            <Header h={3} title={"Описание параметров"} style={{marginTop: 30, flexDirection: isMobile? "column": ""}} />
            <InstructionImgContainer src={nine_1}
                                     caption={ isMobile?
                                         <div>Рисунок 1. Параметры и их значения</div>:
                                         <div>Рисунок 1. Параметры <br/><span style={{marginLeft: 102}}>и их значения</span></div>
            }
            />

            <div>
                (1) Если ваш уровень равен уровню земли, то в значении параметра “Уровень земли” необходимо поставить галочку (рис. 1).
            </div>
            <div>
                (2) Если ваш ваш этаж является мансардным, техническим и т.д, {isMobile? null: <br/>} то необходимо в параметре «Тип этажа» выбрать соответствующее значение параметра (рис.1).
            </div>

            <Header h={3} title={"Общие рекомендации"} style={{marginTop: 30}}/>
            <InstructionImgContainer src={nine_2} caption={ <div>Рисунок 2. Элемент “Этаж”</div>}/>

            <div>
                Рекомендуем для исключения пересечения уровней оставлять границы 3D уровня в пределах проектируемого этажа.
            </div>
        </>
    );
};

export default NineTab;