import React from 'react';
import FlexContainer from "../containers/FlexContainer";
import {hFont} from "../../Theme";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    hover: {
        cursor: "pointer",
        "&:hover":{
            color: "#fff"
        }
    }
}))

const MenuItem = (props) => {
    const classes = useStyles();
    const {active, name} = props
    return (
        <div className={"hover"}>{name}</div>
    );
};

MenuItem.propTypes = {
    icon: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    active: PropTypes.bool,

    style: PropTypes.object,
}

export default MenuItem;
