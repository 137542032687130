import React, {useState} from 'react';
import TrueGrid from "../../theme/components/containers/TrueGrid";
import logo_mobile from "../assets/img/logo_mobile.png"
import reg_mobile from "../assets/img/reg_mobile.png"
import FooterLink from "../footer/components/FooterLink";
import {routes} from "../../services/router/routes";
import {Button} from "@mui/material";
import {palette} from "../../theme/Theme";
import {onWindowOpen} from "../../theme/functions/functions";

const MobileMenu = () => {
    const [display, setDisplay] = useState("none")
    const displayHandler = () => {
        setDisplay(display==="block"? "none": "block")
        let body = document.querySelector("body")
        if (body) body.style.overflowY = display==="block"? "auto": "hidden"
    }
    return (
        <>
            <div style={{background: "#225078", padding: "20px 25px", display: "flex", alignItems: "center", gap: 20, position: "sticky", top: 0, zIndex: 10}}>
                <div style={{cursor: "pointer"}} onClick={displayHandler}>
                    <TrueGrid gap={10}>
                        <div style={{width: 35, height: 2, background: "#fff"}}/>
                        <div style={{width: 35, height: 2, background: "#fff"}}/>
                        <div style={{width: 35, height: 2, background: "#fff"}}/>
                    </TrueGrid>
                </div>
                <img src={logo_mobile} style={{height: 35, objectFit: "contain"}}/>
                <a href={"https://normastroy.ru/login"} target={"_blank"} style={{display: "flex", margin: "auto 0px auto auto"}}  onClick={e=>onWindowOpen(e, "https://normastroy.ru/login")}>
                    <img src={reg_mobile} style={{height: 35, objectFit: "contain"}}/>
                </a>
            </div>
            <div style={{
                display: display,
                background: "#103F62",
                position: "fixed",
                top: 75,
                height: "calc(100vmax - 75px)",
                width: "100vw",
                zIndex: 10,
                padding: "40px 25px",
                maxHeight: "calc(100vmax - 75px)",
                overflowY: "auto"
            }}>
                <TrueGrid gap={28} style={{paddingLeft: 25, borderLeft: "1px solid #fff"}}>
                    <TrueGrid gap={5}>
                        <FooterLink to={routes.home.path} text={"Главная"}  onClick={displayHandler}/>
                        <FooterLink to={routes.check.path} text={routes.check.name}  onClick={displayHandler}/>
                    </TrueGrid>
                    <TrueGrid gap={5}>
                        <FooterLink to={"#"} text={"Скачать инструкцию по заполнению параметров"}  onClick={displayHandler}/>
                        <FooterLink to={"#"} text={"Скачать пример модели"}  onClick={displayHandler}/>
                    </TrueGrid>

                    <TrueGrid gap={5}>
                        <FooterLink to={"#"} text={"Начать поиск НТД на сайте..."}  onClick={displayHandler}/>
                    </TrueGrid>

                    <TrueGrid gap={5} style={{justifyItems: "start"}}>
                        <a href={"https://normastroy.ru/login"} target={"_blank"}>
                            <Button variant={"contained"} style={{background: "#fff", color: palette.accent}} onClick={e=>onWindowOpen(e, "https://normastroy.ru/login", displayHandler)}>Войти</Button>
                        </a>
                        <a href={"https://normastroy.ru/registration"} target={"_blank"}>
                            <Button variant={"contained"}  onClick={e=>onWindowOpen(e, "https://normastroy.ru/registration", displayHandler)}>Регистрация</Button>
                        </a>
                    </TrueGrid>

                    <TrueGrid gap={5}>
                        <FooterLink to={"mailto: normastroy@gmail.com"} text={"normastroy@gmail.com"}  onClick={displayHandler}/>
                    </TrueGrid>

                    <TrueGrid gap={5}>
                        <FooterLink to={"#"} text={"Пользовательское соглашение"} onClick={displayHandler}/>
                        <FooterLink to={"#"} text={"Политика конфиденциальности"}  onClick={displayHandler}/>
                        <FooterLink to={"#"} text={"Информация для рекламодателей"}  onClick={displayHandler}/>
                    </TrueGrid>
                </TrueGrid>
            </div>
        </>
    );
};

export default MobileMenu;