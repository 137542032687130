import React from 'react';
import PropTypes from 'prop-types';
import {palette} from "../../../../theme/Theme";
import useMobile from "../../../../theme/functions/hooks/useMobile";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    box: {
        borderRadius: 10,
        padding: "60px 40px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "15px",
        cursor: "pointer",
        transition: "all 0.2s",
        "&:hover":{
            background: "#005EDE !important"
        },
        "&:active":{
            filter: "contrast(0.8)"
        },
    }
}))


const CheckerAction = props => {
    const {item} = props
    const isMobile = useMobile()
    const classes= useStyles()

    return (
        <div onClick={item.onClick} className={classes.box} style={{
            background: item?.active? palette.accent :"linear-gradient(104.99deg, #54718A 10.52%, #005EDE 107.22%)",
            width: isMobile? "100%" :item.width,
            ...item.style,
        }}>
            <img src={item.src} alt={"Icon"} style={{height: 60, objectFit: "contain"}}/>
            <div style={{textAlign: "center"}}>{ item?.active? item.activeText: item.text}</div>
        </div>
    );
};

CheckerAction.propTypes = {

};

export default CheckerAction;