import React from 'react';
import Layout from "../../layout/containers/Layout";
import {routes} from "../../services/router/routes";
import Section from "../../theme/components/containers/Section";
import Header from "../../theme/components/typography/Header";
import Hr from "../../theme/components/typography/Hr";
import Tabs from "./tabs/Tabs";
import useMobile from "../../theme/functions/hooks/useMobile";

const InstructionPage = () => {
    const isMobile = useMobile()
    return (
        <Layout title={routes.instruction.name} fetching={false}>
            <Section style={{paddingBottom: 30, paddingTop: isMobile? 50: 64}}>
                <Header h={2} title={"Инструкция по заполнению параметров"} fontStyle={{textAlign: isMobile? "center": ""}}/>
            </Section>
            <Section style={{paddingTop: 0}}>
                <Tabs/>
            </Section>
        </Layout>
    );
};

export default InstructionPage;