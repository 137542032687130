import React from 'react';
import PropTypes from 'prop-types';
import "./style.css"

const TextInput = props => {
    return (
        <div>
            <div>
                <b style={{color: "#fff"}}>{props.title}</b>
            </div>
            <input placeholder={props.placeholder}
                   className={"white-placeholder"}
                   value={props.value}
                   type={"number"}
                   min={0}
                   onChange={e=>props.setValue(e.target.value)}
                   style={{
                marginTop: 10,
                background: "transparent",
                borderRadius: 10,
                border: "2px solid #fff",
                padding: "10px 15px",
                display: "block",
                width: "100%"
            }}/>
        </div>
    );
};

TextInput.propTypes = {
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
};

export default TextInput;