import React, {Suspense} from 'react';
import {Route, Routes} from "react-router-dom";
import {routes} from "./routes";
import TopLoader from "../../theme/components/containers/TopLoader";

const MyRouter = () => {
    return (
        <Suspense fallback={ <TopLoader fetching={true}/>}>
            <Routes>
                {Object.values(routes).map(el=>
                    <Route path={`${el.path}${el.id? `/:${el.id}`: ""}`} element={el.component} key={el.path}/>
                )}
            </Routes>
        </Suspense>
    );
};

export default MyRouter;
