import React from 'react'
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {ru} from "date-fns/locale";
import "./styles/Fonts.css"
import "./styles/_app.scss"
import {isMobileFunc} from "./functions/functions";

export const input = {
    background: "#edeef0",
    borderRadius: 10,
    borderWidth: "0px",
}
export const hFont = "Roboto, sans-serif"
export const containerWidth = 1220
export const maxMobileImgWidth = "min(600px, 100%)"

export const palette = {
    back: "#103F62",
    accent: "#005EDE",
    dropShadow: "drop-shadow(0px 0px 10px #fff)",

    success: "#00ca1f",
    warning: "#ffb600",
    error: "#f44336",
}

export const sticky = {
    position: "sticky",
    top: 0,
    zIndex: 2,
}

export const customScrollY = {
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        width: 5, height: 5,
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: "100px", background: "#fff",
    },
}

const theme = createTheme({
    typography: {
        fontSize: isMobileFunc()? 17 : 19,
        color: "#fff",
        fontFamily: hFont,
        body1: {
            fontSize: isMobileFunc()? 17 : 19,
            color: "#fff",
        },
    },

    palette: {
        primary: {
            main: palette.accent,
            dark: palette.accent,
            contrastText: '#fff',
        },
        secondary: {main: "#000"}
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 700,
                    borderRadius: 10,
                    fontFamily: hFont,
                    padding: "10px 35px"
                },
                contained: {
                    boxShadow: "none",
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',
                    padding: "22px 22px",
                    //background: palette.back2,
                    boxShadow: "none",
                },
            }
        },
        MuiOutlinedInput:{
            styleOverrides: {
                root: {
                    ...input
                },
                notchedOutline: {
                    borderWidth: 0,
                },
            }
        },
        MuiDialog:{
            styleOverrides: {
                paper: {
                    borderRadius: 15,
                    padding: 10,
                }
            }
        },
        MuiPopover:{
            styleOverrides:{
                paper: {
                    borderRadius: 10,
                    padding: "15px 20px",
                    boxShadow: "none",
                    border: palette.border
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    color: "#fff",
                    fontWeight: 700
                }
            }
        }
    },
});
const Theme = (props) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {props.children}
        </ThemeProvider>
    )
}

export default Theme
