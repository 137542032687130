import {logError} from "../../../theme/functions/errors";
import {FAILED, REQUEST, SUCCESS} from "../../../services/const";
import {API_PATH} from "../../../services/const/paths";
import {call} from "../../../services/calls/calls";


const GET_DATA = "GET_DATA"
const POST_DATA = "POST_MODEL"

const DEFAULT_STATE = {
    fetchingModel: false,
    isPost: false,
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + POST_DATA:
            return {
                ...state,
                fetchingModel: true,
            }

        case SUCCESS + POST_DATA:
            return {
                ...state,
                fetchingModel: false,
                isPost: true,
            }

        case  FAILED + POST_DATA:
            logError("Не удалось отправить файл")
            alert("Не удалось отправить файл")
            return {
                ...state,
                fetchingModel: false,
            }
        default: {
            return state;
        }
    }
};

export const postModel = (body) => call(
    "POST",
    `${API_PATH}/model`,
    POST_DATA,
    body,
    null,
    null,
    true
)