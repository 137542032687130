import React, {useEffect, useRef} from 'react';
import {containerWidth, palette, sticky} from "../../theme/Theme";
import {Link, useLocation,} from "react-router-dom";
import {menu} from "./menu";
import MenuItem from "../../theme/components/muiOverrides/MenuItem";
import FlexContainer from "../../theme/components/containers/FlexContainer";
import logo from "../assets/img/img.png"
import {Button} from "@mui/material";
import {DEFAULT_PATH} from "../../services/router/pageConsts";
import {onWindowOpen} from "../../theme/functions/functions";

const TopMenu = () => {
    const location = useLocation()
    const ref = useRef()

    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    function stickyTop () {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 80) {
            ref.current.classList.remove(scrollUp);
            return;
        }
        if (currentScroll > lastScroll && ! ref.current.classList.contains(scrollDown)) {
            // down
            ref.current.classList.remove(scrollUp);
            ref.current.classList.add(scrollDown);
        } else if (
            currentScroll < lastScroll &&
            ref.current.classList.contains(scrollDown)
        ) {
            // up
            ref.current.classList.remove(scrollDown);
            ref.current.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    }

    useEffect(()=>{
        window.addEventListener("scroll", stickyTop)
        return ()=> window.removeEventListener("scroll", stickyTop)
    }, [])

    return (
        <div style={{background: "#225078",}} ref={ref}>
            <div style={{ maxWidth: containerWidth, padding: "15px 20px", display: "block", margin: "auto"}}>
                <FlexContainer gap={60}>
                    <Link to={DEFAULT_PATH}>
                        <img src={logo} alt={"Logo"} style={{width: 60, height: 60}}/>
                    </Link>

                    <nav>
                        <FlexContainer gap={50}>
                            {menu.map(el=>
                                <Link to={el.path} key={el.path} onClick={el.onClick}>
                                    <MenuItem active={ location.pathname === el.path} name={el.name} icon={el.icon}/>
                                </Link>
                            )}
                        </FlexContainer>
                    </nav>

                    <FlexContainer gap={30} style={{marginLeft: "auto"}}>
                        <a href={"https://normastroy.ru/registration"} target={"_parent"}>
                            <Button variant={"contained"} style={{fontWeight: 500}} onClick={e=>onWindowOpen(e, "https://normastroy.ru/registration")}>Регистрация</Button>
                        </a>
                        <a href={"https://normastroy.ru/login"} target={"_parent"}>
                            <Button variant={"contained"} style={{background: "#fff", fontWeight: 500, color: palette.accent}} onClick={e=>onWindowOpen(e, "https://normastroy.ru/login")}>Войти</Button>
                        </a>
                    </FlexContainer>
                </FlexContainer>
            </div>
        </div>
    );
};

export default TopMenu;
