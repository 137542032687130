//import fetch from 'isomorphic-fetch'

export const CALL_API = Symbol('Call API');

export function getCookie(name) {
    const matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const callApi = (endpoint, config) => {
    // Check if config is passed
    if (typeof config !== 'object') {
        return Promise.reject({status: false, msg: 'INVALID_REQUEST'})
    }

    // Check if token exists
    let token = getCookie("jwt") || localStorage.getItem('jwt') || null;

    config.credentials = 'same-origin';
    config.headers['Authorization'] = `BEARER ${token}`;

    return fetch(`${endpoint}`, config)
        .then(response => response.json().then(json => {
            if (!response.ok || response.status >= 400) {
                // throw new Error('Bad response from server');
                return Promise.reject({msg: response.statusText, err: 'Network error'})
            }
            // if (String(json.status) !== "true") {
            //     if (!!json.redirect) {
            //         return window.location.replace(json.redirect)
            //     }
            //     console.log('reject from here!')
            //     return Promise.reject(json)
            // }
            return json;
        }))
        .then((json) => (json));
};

export default store => next => action => {

    const callAPI = action[CALL_API];

    // So the middleware doesn't get applied to every single action
    if (typeof callAPI === 'undefined') {
        return next(action)
    }

    let {endpoint, types, config, payloadFilter, actions} = callAPI;

    const [requestType, successType, errorType] = types;

    next({
        type: requestType
    });


    // Passing the authenticated boolean back in our data will
    // let us distinguish between normal and secret quotes
    return callApi(endpoint, config).then(
        response => {
            if (payloadFilter) {
                response = payloadFilter(response);
            }
            next({
                type: successType,
                payload: response
            });
            if (actions) {
                actions(next, response);
            }
        },
        error => next({
            type: errorType,
            payload: error.err || error || 'Похоже, возникла непредвиденная ошибка'
        })
    ).catch(e => next({
        type: errorType,
        payload: e
    }));
}
