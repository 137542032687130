import React from 'react';
import PropTypes from 'prop-types';
import FlexContainer from "../../../../../theme/components/containers/FlexContainer";
import useMobile from "../../../../../theme/functions/hooks/useMobile";

const InstructionImgContainer = props => {
    const isMobile = useMobile()

    return (
        <FlexContainer style={{alignItems: "start", flexDirection: isMobile? "column":""}} gap={20}>
            <img src={props.src} style={{maxWidth: 390, objectFit: "contain"}}/>
            {props.caption}
        </FlexContainer>
    );
};

InstructionImgContainer.propTypes = {
    src: PropTypes.any,
    caption: PropTypes.element,
};

export default InstructionImgContainer;