import {toast} from "react-toastify";

export const logError = (userText, serverError) => {
    toast.error(`${userText}.`);
    console.error(serverError);
}

export const logInfo = (userText) => {
    toast.info(`${userText}`);
    console.info(userText);
}

export const logSuccess = (userText) => {
    toast.success(`${userText}`);
}
